<template>
  <q-stepper-navigation>
    <q-btn
      v-if="currentStep > 1"
      flat
      @click="previousStep()"
      color="primary"
      label="Terug"
      class="q-ml-sm"
    />
    <q-btn
      color="primary"
      @click="nextStep()"
      label="Ga naar de volgende stap"
      :loading="loading"
    />
  </q-stepper-navigation>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

// eslint-disable-next-line no-undef
defineProps({
  loading: Boolean,
});

//* VueUse
// eslint-disable-next-line no-undef
const emit = defineEmits(["nextStep", "previousStep"]);
const $store = useStore();

//* Computed
const currentStep = computed(() => $store.getters.record.current_step);

//* Methods
function nextStep() {
  emit("nextStep");
}

function previousStep() {
  emit("previousStep");
}
</script>

<style lang="sass"></style>
