<template>
  <div class="row">
    <div :class="colSize">
      <div class="text-overline text-weight-bold q-pb-md" v-if="!toggleTQIS">
        Bel TQIS nu voor de live verificatie op +073 20 04 578.
      </div>
      <div class="text-overline text-weight-bold q-pb-md" v-if="toggleTQIS">
        Voer de code 9999 in om verder te gaan.
      </div>
      <q-input
        v-model="verification"
        ref="verificationRef"
        label="Verificatie code"
        outlined
        style="width: 50%"
        :rules="[
          (val) => val != '' || 'Dit is een verplicht veld',
          (val) =>
            (val !== null && val !== '' && /^[1-9][0-9]*$/.test(val)) ||
            'Dit veld mag alleen getallen bevatten',
        ]"
      >
      </q-input>
      <div
        class="text-italic text-caption q-pl-sm text-grey-9"
        v-if="!toggleTQIS"
      >
        TQIS is doordeweeks van 9.00 tot 21.00 uur bereikbaar en zaterdag van
        10.00 tot 18.00 uur. <br />
        Het kan zijn dat je even moet wachten, bij een storing kan je contact
        opnemen met Service op +020 21 70 160.
      </div>
    </div>
    <div class="col-12 q-pt-xl">
      <q-stepper-navigation class="row">
        <div class="col-6">
          <q-input
            :label="'Record ID: ' + recordId"
            dense
            disable
            filled
            style="width: fit-content"
          />
        </div>
        <div class="col-6">
          <div class="row justify-end">
            <q-btn
              flat
              @click="previousStep()"
              color="primary"
              label="Terug"
              class="q-ml-sm"
            />
            <q-btn
              color="primary"
              @click="verifyTQIS()"
              label="Ga naar de volgende stap"
            />
          </div>
        </div>
      </q-stepper-navigation>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

export default {
  setup() {
    const $q = useQuasar();
    const $store = useStore();

    const verificationRef = ref(null);
    const verification = ref("");

    const recordId = computed(() => {
      return $store.getters.record.id ?? "";
    });

    const colSize = computed(() => {
      return $q.screen.lt.md ? "col-12" : "col-6";
    });

    const toggleTQIS = computed(() => {
      return $store.getters.toggleTQIS ?? false;
    });

    $store.dispatch("toggleTQIS");

    return {
      verification,
      verificationRef,
      recordId,
      colSize,
      toggleTQIS,
    };
  },
  methods: {
    verifyTQIS() {
      this.verificationRef.validate();

      if (this.verificationRef.hasError) {
        return;
      }

      this.$store.dispatch("verifyTQIS", this.verification);
    },
    previousStep() {
      this.$store.dispatch("removeTQIS");
      this.$store.dispatch("decrementCurrentStep");
      this.$store.dispatch("updateStatus", {
        status: 1,
        reason: "",
      });
    },
  },
};
</script>

<style lang="sass"></style>
